import { makeAutoObservable } from "mobx";
import { Draft } from "./models/draft.models";
import { StepFirstModel } from "./models/forms.models";

class SrmStore {
    drafts: Draft[] = [];

    // undefined = is not loaded, null - is null )
    currentDraft?: Draft | undefined | null = undefined;
    finalScreenModel?: StepFirstModel = undefined;
    project?: string;
    version?: string;

    constructor() {
        makeAutoObservable(this);
    }

    get currentDraftIsNotLoaded(): boolean {
        return this.currentDraft === undefined;
    }

    get currentDraftIsLoaded(): boolean {
        return this.currentDraft !== undefined;
    }

    get currentDraftIsEmpty(): boolean {
        return this.currentDraft === null;
    }

    get projectVersionIsNotSet(): boolean {
        return (!this.project || !this.version);
    }

    setCurrentProjectVersion(project, version) {
        this.project = project;
        this.version = version;
    }

    setCurrentDraft(draft: Draft | null) {
        this.currentDraft = draft;
    }

    setDrafts(drafts: Draft[]) {
        this.drafts = drafts;
    }

    updateDraft(draft: Draft) {
        const ind = this.drafts.findIndex(d => d.id === draft.id);

        if (ind === -1) {
            return;
        }

        this.drafts[ind] = draft;
    }

    deleteDraft(draft: Draft) {
        this.drafts = this.drafts.filter(d => d.id !== draft.id);
    }

    setFinalScreenData(stepFirstModel: StepFirstModel) {
        this.finalScreenModel = stepFirstModel;
    }

    reset(resetFinalScreenData = true) {
        resetFinalScreenData && this.setFinalScreenData(undefined);
        this.setCurrentDraft(undefined);
        this.setDrafts([]);
        this.setCurrentProjectVersion(undefined, undefined);
    }
}

export default SrmStore;
