import { SaveAnalyticsService } from "@analytics-ring/analytics-ring-collector-client";

export const saveAnalytic = (analyticServicePromise: Promise<SaveAnalyticsService | undefined> | undefined, action: string, lkid?: string) => {
    const isClientSide = typeof window !== 'undefined';

    if (isClientSide && analyticServicePromise) {
        setTimeout(() => analyticServicePromise?.then(service => service?.save({
                action,
                lkid,
            })
        ), 100);
    }
}
