import React, { FC, useEffect } from "react";

import { Router } from "next/router";

import { saveAnalytic } from "../../data/analytics/analytics.actions";

import { AnalyticsActions } from "../../data/analytics/analytics.actions.consts";
import { DataContextData } from "../../data/initializeAll";
import { NextComponentType, NextPageContext } from "next";
import { DataContext } from "../../data/DataContext";
import { observer } from "mobx-react-lite";

export interface ApplicationProps {
    Component: NextComponentType<NextPageContext, any>;
    pageProps: any;
    router: Router;
    contextData: DataContextData;
}

const Application: FC<ApplicationProps> = (props) => {
    const {
        Component,
        pageProps,
        router,
        contextData,
    } = props;

    const { stores, services } = contextData;
    const { isLogged, userId } = stores.authStore;

    useEffect(() => {
        if (isLogged) {
            services.authService.zeroOutCurrentVerificationAttemptsCount();
        } else if (!isLogged) {
            services.authService.refreshAuthData().then();
        }
    }, [ isLogged ]);

    useEffect(() => {
        saveAnalytic(services.analyticServiceAsync, AnalyticsActions.ENTER_TO_PAGE, userId?.toString());
    }, [ router.asPath ]);

    return (
        <DataContext.Provider value={contextData}>
            <Component {...pageProps} />
        </DataContext.Provider>
    );
}

export default observer(Application);
