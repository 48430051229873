import '../styles/fonts.scss';
import '../styles/variables.scss';
import '../styles/mixins.scss';
import '../styles/styles.scss';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'tippy.js/dist/tippy.css';

import PrimeReact from 'primereact/api';
import { AppProps } from 'next/app';
import initializeAll, { DataContextData } from "../data/initializeAll";
import Application from "../components/Application/Application";
import { useState } from "react";

const App = ({ Component, pageProps, router }: AppProps) => {
    PrimeReact.ripple = true;

    const [ contextData ] = useState<DataContextData>(() => initializeAll());

    return <Application
        Component={Component}
        pageProps={pageProps}
        router={router}
        contextData={contextData}
    />
};

// noinspection JSUnusedGlobalSymbols
export default App;
