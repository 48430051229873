import { CfpEvent } from "../data/srm/models/events.models";

export type ApplicationRoute = typeof APPLICATION_ROUTES;

export const IS_PARNERS_QUERY_PARAM = "isPartner";
export const IS_PARNERS_DOMAIN_PARAM = ".partner.";
export const IS_MEETUP_DOMAIN_PARAM = ".meetup.";
export const IS_MEETUP_QUERY_PARAM = "isMeetup";

export const APPLICATION_ROUTES = {
    main: {
        route: '/',
        createRoute: (path?: string) => `/${path === undefined ? '' : path}`,
        createRouteForCfp: (event: CfpEvent, isPartnerFlag: boolean) => `/${event.project.toLowerCase()}-${event.version.toLowerCase()}${isPartnerFlag ? `/?${IS_PARNERS_QUERY_PARAM}` : ""}`,
        params: {},
    },
}
