import { makeAutoObservable } from "mobx";
import { AppState } from "./AppState";
import { getStateFromLocalStorage, setStateToLocalStorage } from "../../utils/localStorage";

class AppStateStore {
    public appState?: AppState;

    constructor(private readonly key: string, defaultAppState: AppState) {
        makeAutoObservable(this);
        this.init(key, defaultAppState);
    }

    private init(key: string, defaultAppState: AppState) {
        this.appState = getStateFromLocalStorage(key) ?? defaultAppState ?? undefined;
    }

    private save() {
        setStateToLocalStorage(this.appState, this.key);
    }

    public updateAppState(state: AppState) {
        this.appState = state;
        this.save();
    }
}

export { AppStateStore }
