import {getScopeFromDomain} from "./src/utils/url-parser";

export const release = `CallForPapers v.${(process.env.RELEASE ?? process.env.NEXT_PUBLIC_RELEASE ?? "0.0.1").replace("/", "_")}`;

export const STAGE = 'dev';

// it's necessary so that the bash script does not correct this line
const STAGE_FOR_LOCAL_USAGE = 'STAGE' + '_NAME' + '_FROM' + '_NODE' + '_STAGE';

export const useMocks = process.env.USE_MOCKS === "true";

const environments = {
    local: 'local',
    dev: 'development',
    test: 'testing',
    prod: 'production',
}

export const getScope = host => host ? getScopeFromDomain(host, true) : typeof window !== "undefined" ? getScopeFromDomain(window.location.href, false) : undefined;

export const getEnvironment = () => {
    if (STAGE === STAGE_FOR_LOCAL_USAGE) {
        return environments.local;
    }

    return environments[STAGE] ?? environments.dev;
}

export const isLocalEnvironment = getEnvironment() === environments.local;
export const isProduction = getEnvironment() === environments.prod;
// export const isDevelopment = getEnvironment() === environments.dev;
// export const isTesting = getEnvironment() === environments.test;

export const getHostUrl = () => {
    const urlSubDomain =
        getScope(undefined) === 'PARTNER' ? ".partner" :
            getScope(undefined) === 'MEETUP' ? ".meetup" :
                "";

    return {
        'local': `https://callforpapers-dev${urlSubDomain}.jugru.org/`,
        'development': `https://callforpapers-dev${urlSubDomain}.jugru.org/`,
        'testing': `https://callforpapers-test${urlSubDomain}.jugru.org/`,
        'production': `https://callforpapers${urlSubDomain}.jugru.org/`,
    }[getEnvironment()]
}

export const getBackendHostUrl = () => {
    return {
        'local': 'https://callforpapers-dev.jugru.org/',
        'development': 'http://development-srm-backend-service.srm-backend-260-development.svc.cluster.local:8080/',
        'testing': 'http://testing-srm-backend-service.srm-backend-260-testing.svc.cluster.local:8080/',
        'production': 'http://production-srm-backend-service.srm-backend-260-production.svc.cluster.local:8080/',
    }[getEnvironment()]
}

export const getLKUrl = () => {
    return {
        'local': 'https://lk-dev.jugru.org/',
        'development': 'https://lk-dev.jugru.org/',
        'testing': 'https://lk-test.jugru.org/',
        'production': 'https://my.jugru.org/',
    }[getEnvironment()]
}

export const getRingoStarUrl = () => {
    return {
        'local': 'https://dev-ringostar.jugru.team/api/v2/collector/collect',
        'development': 'https://dev-ringostar.jugru.team/api/v2/collector/collect',
        'testing': 'https://test-ringostar.jugru.team/api/v2/collector/collect',
        'production': 'https://ringostar.jugru.team/api/v2/collector/collect',
    }[getEnvironment()]
}
