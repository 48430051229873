export const countHoursBetweenDates = (date1: Date, date2: Date): number => {
    return Math.ceil(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600));
};

export const countHoursBetweenDatesMoreThen = (date1: Date, date2: Date, delta: number): boolean => {
    return countHoursBetweenDates(date1, date2) >= delta;
};

export const countMinutesBetweenDates = (date1: Date, date2: Date) => {
    let diff = (date2.getTime() - date1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
};

export const getSeason = date => Math.floor(((date.getMonth() + 1) / 12 * 4)) % 4;
