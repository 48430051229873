export const removeUndefined = obj => {
    if (Array.isArray(obj)) {
        return obj.filter(a => a);
    }

    for (const k in obj) {
        if (obj[k] === undefined) {
            delete obj[k];
        }
    }
    return obj;
};

export function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
}
