import AnalyticsClientProviderAsync from "./analytics/AnalyticsClientProviderAsync";
import { AuthService } from "./auth/auth.service";
import { SaveAnalyticsService } from "@analytics-ring/analytics-ring-collector-client";

import { AuthApiService } from "./auth/auth.api.service";
import { AuthStore } from "./auth/AuthStore";
import { SrmApiService } from "./srm/srm.api.service";
import { CFP_APPSTATE_KEY, defaultAppState } from "./app/AppState";
import { NotificationsStore } from "./notifications/notifications.store";
import { AppStateStore } from "./app/AppStateStore";
import SrmStore from "./srm/srm.store";
import SrmService from "./srm/srm.service";

export interface DataContextData {
    stores: {
        authStore: AuthStore;
        notificationsStore: NotificationsStore;
        appStateStore: AppStateStore;
        srmStore: SrmStore;
    },
    services: {
        authService: AuthService;
        analyticServiceAsync: Promise<SaveAnalyticsService | undefined>;
        authApiService: AuthApiService;
        srmApiService: SrmApiService;
        srmService: SrmService;
    }
}

const initializeAll = (): DataContextData => {
    const authStore = new AuthStore();

    const appStateStore = new AppStateStore(CFP_APPSTATE_KEY, defaultAppState);
    // temporary lock
    // const configService = new ConfigService();

    const authApiService = new AuthApiService();

    const authService = new AuthService(authApiService, authStore);

    const srmStore = new SrmStore();

    const srmApiService = new SrmApiService(false, authStore, authService);

    const srmService = new SrmService(srmStore, srmApiService);

    const analyticServiceAsync = new AnalyticsClientProviderAsync().getSaveAnalyticsServiceAsync();

    const notificationsStore = new NotificationsStore(authStore);

    return {
        stores: {
            authStore,
            notificationsStore,
            appStateStore,
            srmStore
        },
        services: {
            authApiService,
            authService,
            analyticServiceAsync,
            srmApiService,
            srmService
        }
    };
}

export default initializeAll;
