export const setStateToLocalStorage = <T extends object>(state: T, key: string) => {
    if (typeof window !== "undefined") {
        localStorage.setItem(key, JSON.stringify(state));
    }
}

export const getStateFromLocalStorage = <T extends object>(key: string): T | undefined => {
    if (typeof window !== "undefined") {
        const stringState = localStorage.getItem(key);
        if (!stringState) {
            return undefined;
        }
        try {
            return JSON.parse(stringState);
        } catch (e) {
            return undefined;
        }
    }
    return undefined;
};
