import { ActivityFormat, ActivityTypeValue, DraftScope } from "./draft.models";
import { removeUndefined } from "../../../utils/common";

export interface StepFirstModel {
    firstName?: string;
    lastName?: string;
    company?: string;
    email?: string;
    phone?: string;
    twitter?: string;
    telegram?: string;
    socialMedia?: string;
    biography?: string;
    whenceType?: string;
    whence?: string;
    acceptGDPR?: boolean;
}

export const instanceOfStepFirstModel = (object: any): object is StepFirstModel => 'firstName' in object;

export interface StepSecondModel {
    format?: ActivityFormat;
    typeValue?: ActivityTypeValue;
    typeDetails?: string;
    coSpeakerCandidates?: string[];
}

export interface StepThirdModel {
    title?: string;
    description?: string;
    plan?: string;
}

export const stepFirstModelDefaultValues: StepFirstModel = {
    firstName: undefined,
    lastName: undefined,
    company: undefined,
    email: undefined,
    phone: undefined,
    twitter: undefined,
    socialMedia: undefined,
    telegram: undefined,
    biography: undefined,
    whence: undefined,
    whenceType: undefined,
    acceptGDPR: false
}

export const stepSecondModelDefaultValues: StepSecondModel = {
    format: "ONLINE_REMOTE",
    typeValue: "TALK",
    typeDetails: undefined,
    coSpeakerCandidates: []
}

export const stepThirdModelDefaultValues: StepThirdModel = {
    title: undefined,
    description: undefined,
    plan: undefined
}

export const isEventOffline = (eventId: number) => {
    // exclude TT, Flow
    // need to check in event catalog

    if (eventId === 100147) {
        return false;
    }

    if (eventId === 100139) {
        return false;
    }

    return true;
}

export const WhenceTypeRadioValues: {
    key: string,
    displayId: string
}[] = [
    {
        key: "DEVREL",
        displayId: "form.step1.fields.whenceType.DEVREL"
    },
    {
        key: "MANAGER",
        displayId: "form.step1.fields.whenceType.MANAGER"
    },
    {
        key: "COMMITTEE_INVITE",
        displayId: "form.step1.fields.whenceType.COMMITTEE_INVITE"
    },
    {
        key: "COORDINATOR_INVITE",
        displayId: "form.step1.fields.whenceType.COORDINATOR_INVITE"
    },
    {
        key: "NEWSLETTER",
        displayId: "form.step1.fields.whenceType.NEWSLETTER"
    },
    {
        key: "SOCIAL_MEDIA",
        displayId: "form.step1.fields.whenceType.SOCIAL_MEDIA"
    },
    {
        key: "I_AM_ONBOARD",
        displayId: "form.step1.fields.whenceType.I_AM_ONBOARD"
    },
    {
        key: "COWORKERS",
        displayId: "form.step1.fields.whenceType.COWORKERS"
    },
    {
        key: "FOUND_IT_MYSELF",
        displayId: "form.step1.fields.whenceType.FOUND_IT_MYSELF"
    },
    {
        key: "CUSTOM",
        displayId: "form.step1.fields.whenceType.CUSTOM"
    },
];

export const ActivityFormatRadioValues = (scope: DraftScope, isShowOffline: boolean): {
    key: string,
    displayId: string
}[] =>
    removeUndefined([
        {
            key: "ONLINE_REMOTE",
            displayId:
                scope === "PARTNER" ?
                    "form.step2.fields.format.ONLINE_REMOTE.partner"
                    : scope === "MEETUP" ?
                        "form.step2.fields.format.ONLINE_REMOTE.meetup"
                        : "form.step2.fields.format.ONLINE_REMOTE"
        },
        // edit by https://jira.jugru.org/browse/SRM-806
        scope === "PARTNER" ?
            {
                key: "ONLINE_STUDIO",
                displayId: "form.step2.fields.format.ONLINE_STUDIO.partner"
            } :
            scope === "MEETUP" ?
                {
                    key: "ONLINE_STUDIO",
                    displayId: "form.step2.fields.format.ONLINE_STUDIO.meetup"
                } :
                undefined,
        // disabled by https://jira.jugru.org/browse/SRM-806
        // {
        //     key: "ONLINE_STUDIO",
        //     displayId:
        //         scope === "PARTNER" ?
        //             "form.step2.fields.format.ONLINE_STUDIO.partner"
        //             : scope === "MEETUP" ?
        //                 "form.step2.fields.format.ONLINE_STUDIO.meetup"
        //                 : "form.step2.fields.format.ONLINE_STUDIO"
        // },
        // temporary disabled by https://jira.jugru.org/browse/SRM-2
        // enabled by https://jira.jugru.org/browse/SRM-343
        scope === "REGULAR" && isShowOffline ? {
            key: "OFFLINE",
            displayId: "form.step2.fields.format.OFFLINE"
        } : undefined,
        scope === "REGULAR" ? {
            key: "UNKNOWN",
            displayId: "form.step2.fields.format.UNKNOWN"
        } : undefined,
    ]);

export const ActivityTypeRadioValues = (scope: DraftScope): { key: string, displayId: string }[] =>
    removeUndefined(
        [
            {
                key: "TALK",
                displayId: "form.step2.fields.type.TALK"
            },
            scope === "MEETUP" ? undefined : {
                key: "WORKSHOP",
                displayId: "form.step2.fields.type.WORKSHOP"
            },
            // disabled by https://jira.jugru.org/browse/SRM-806
            // scope === "MEETUP" ? undefined : {
            //     key: "BATTLE",
            //     displayId: "form.step2.fields.type.BATTLE"
            // },
            scope === "MEETUP" ? undefined : {
                key: "BOF_SESSION",
                displayId: "form.step2.fields.type.BOF_SESSION"
            },
            scope === "MEETUP" ? undefined : {
                key: "MASTERCLASS",
                displayId: "form.step2.fields.type.MASTERCLASS"
            },
            {
                key: "CONVERSATION",
                displayId: scope === "MEETUP" ? "form.step2.fields.type.CONVERSATION.meetup" : "form.step2.fields.type.CONVERSATION"
            },
            scope === "MEETUP" ?
                {
                    key: "INTERVIEW",
                    displayId: "form.step2.fields.type.INTERVIEW"
                } : undefined,
            // disabled by https://jira.jugru.org/browse/SRM-806
            // scope === "MEETUP" ? undefined : {
            //     key: "ANALYSIS",
            //     displayId: "form.step2.fields.type.ANALYSIS"
            // },
            // scope === "MEETUP" ? undefined : {
            //     key: "GAME",
            //     displayId: "form.step2.fields.type.GAME"
            // },
            // scope === "MEETUP" ? undefined : {
            //     key: "CV_INTERVIEW",
            //     displayId: "form.step2.fields.type.CV_INTERVIEW"
            // },
            // scope === "REGULAR" ? {
            //     key: "UNDECIDED",
            //     displayId: "form.step2.fields.type.UNDECIDED"
            // } : undefined,
            scope === "MEETUP" ? undefined : {
                key: "CUSTOM",
                displayId: "form.step2.fields.type.CUSTOM"
            },
        ]
    );
