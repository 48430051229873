import { makeAutoObservable } from "mobx";
import { NotificationSeverenity, NotificationModel } from "./notifications.models";
import { uuidv4 } from "../../utils/uuid";
import { countMinutesBetweenDates } from "../../utils/time";
import { AuthStore } from "../auth/AuthStore";

export class NotificationsStore {
    private notifications: NotificationModel[] = [];
    isLoading: boolean = false;

    constructor(public authStore: AuthStore) {
        makeAutoObservable(this);
        setInterval(() => this.clearNotes(), 10000);
    }

    clearNotes() {
        for (const notification of this.notifications.filter(n => !n.isRead && n.timeToLiveInMin !== undefined)) {
            const diff = countMinutesBetweenDates(notification.created, new Date());
            if (notification.timeToLiveInMin && (diff >= notification.timeToLiveInMin)) {
                notification.isRead = true;
            }
        }
    }

    addError(message: string, title?: string, timeToLiveInMin?: number) {
        const errorNote: NotificationModel = {
            id: uuidv4(),
            title: title ?? "Some Error",
            message,
            created: new Date(),
            importance: "ERROR",
            timeToLiveInMin
        };
        this.notifications.push(errorNote);
    }

    addNote(message: string, title: string, importance: NotificationSeverenity, timeToLiveInMin?: number) {
        const note: NotificationModel = {
            id: uuidv4(),
            title,
            message,
            created: new Date(),
            importance,
            timeToLiveInMin
        };
        this.notifications.push(note);
    }

    addInfoNote(message: string, title: string, timeToLiveInMin?: number) {
        this.addNote(message, title, "INFO", timeToLiveInMin);
    }

    addWarnNote(message: string, title: string, timeToLiveInMin?: number) {
        this.addNote(message, title, "WARNING", timeToLiveInMin);
    }

    markAsRead(id: string) {
        const note = this.notifications.find(n => n.id === id);
        if (note) {
            note.isRead = true;
        }
    }

    markAsShowed(id: string) {
        const note = this.notifications.find(n => n.id === id);
        if (note) {
            note.isShowed = true;
        }
    }

    turnOnGlobalIsLoading() {
        this.isLoading = true;
    }

    turnOffGlobalIsLoading() {
        this.isLoading = false;
    }

    get unreadNotifications() {
        return this.notifications.filter(note => note.isRead === false || note.isRead === undefined);
    }

    get getLastElement(): (NotificationModel | null) {
        if (this.unreadNotifications.length > 0) {
            return this.unreadNotifications[this.unreadNotifications.length - 1];
        }
        return null;
    }

    // @action
    // getLastError() {
    //     if (this.errors.length > 0) {
    //         return this.errors.pop();
    //     }
    // }
}
