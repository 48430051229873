export enum HttpCodes {
    OK = 200,
    CREATED = 201,

    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,

    I_AM_TEAPOT = 418,

    SERVER_ERROR = 500
}
