const atobin = (b64Encoded: string) => {
    return new Buffer(b64Encoded, 'base64').toString('binary');
};

const b64DecodeUnicode = (str: string) =>
    decodeURIComponent(
        Array.prototype.map.call(atobin(str), c =>
            '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        ).join(''))

export const parseJwt = <T>(token: string): T | null => {
    if (!token) {
        return null;
    }

    const tokenInfo = token.split('.')[1];

    if (!tokenInfo) {
        return null;
    }

    return JSON.parse(
        b64DecodeUnicode(
            tokenInfo?.replace('-', '+')?.replace('_', '/')
        )
    );
}

